import React from "react";
import Fade from "react-reveal/Fade";

const Web = () => {
  return (
    <div className="container">
      <Fade>
        <h1>Utmaningar med Webb- eller Apputveckling?</h1>
      </Fade>
      <Fade bottom delay={200}>
        <>
          <p>
            Vi har en lång erfarenhet av applikationsutveckling med React och
            Angular.
          </p>
          <p>
            Kontakta oss på{" "}
            <a href="mailto:kontakta@stik.nu">kontakta@stik.nu</a> så hjälper vi
            dig!
          </p>
        </>
      </Fade>
    </div>
  );
};

export default Web;
