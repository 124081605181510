import React from "react";
import Fade from "react-reveal/Fade";
// import { Link } from "react-router-dom";
import stikLogo from "../img/stik.png"

const Start = () => {
  return (
    <div className="container">
      <header>
        <img 
          className="stik-logo img-responsive"
          src={stikLogo} 
          alt="Stockholms Teknik och IT Konsult"
        />
        {/* <Fade top>
          <h1>Stockholms Teknik och IT Konsult</h1>
        </Fade> */}
        {/* <h2>
          Vi hjälper er med att lösa era <Link to="/webb">Webb</Link>, <Link to="/webb">App</Link> och <Link to="/aws">AWS</Link> utmaningar.
        </h2> */}
        <h2>Front-end specialister. JavaScript, React, Angular, AWS.</h2>
      </header>
      <main>
        <Fade bottom delay={700}>
          <p>
            <a className="contact" href="mailto:kontakta@stik.nu">
              kontakta@stik.nu
            </a>
          </p>
        </Fade>
      </main>
      <footer>
        <p>Stockholms Teknik och IT Konsult AB</p>
      </footer>
    </div>
  );
};

export default Start;
