import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Start from "./containers/Start";
import Web from "./containers/Web";
import Aws from "./containers/Aws";

import "./App.css";

const App = (props) => {
  console.log(props);
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Start} />
        <Route exact path="/webb" component={Web} />
        <Route exact path="/aws" component={Aws} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
